import Header from "@/components/Header/Header";
import EventsSection from "../components/sections/EventsSection";
import Banner from "@/assets/img/banner/kion.png";
import AdditionalTariffsSection from "@/components/sections/AdditionalTariffsSection";
import { useScrollToTop } from "@/hooks";
import Footer from "@/components/Footer/Footer";
import { useSelector } from "react-redux";

const KionPage = () => {
  useScrollToTop();
  const tariffs = useSelector((state) =>
      state.tariffs.items.filter((tariff) => ["kion"].includes(tariff.type))
  );

  return (
    <>
      <Header />
      <img className="movie-banner" src={Banner} alt="" />
      <AdditionalTariffsSection title="Тарифы" tariffs={tariffs} />
      <EventsSection />
      <Footer />
    </>
  );
};

export default KionPage;
