import Header from "@/components/Header/Header";
import EventsSection from "../components/sections/EventsSection";
import Button from "@/components/UI/Button";
import Banner from "@/assets/img/banner/24tv.jpeg";
import AdditionalTariffsSection from "@/components/sections/AdditionalTariffsSection";
import { useScrollToTop } from "@/hooks";
import Footer from "@/components/Footer/Footer";
import { useSelector } from "react-redux";

const TV24Page = () => {
  useScrollToTop();
  const tariffs = useSelector((state) =>
    state.tariffs.items.filter((tariff) => ["24tv"].includes(tariff.type))
  );

  return (
    <>
      <Header />
      <img className="movie-banner" src={Banner} alt="" />
      <div className="centered">
        <a href="https://24h.tv/provider/ellco" target="_blank">
          <Button>Подробнее</Button>
        </a>
      </div>
      <AdditionalTariffsSection title="Тарифы" tariffs={tariffs} />
      <EventsSection />
      <Footer />
    </>
  );
};

export default TV24Page;
