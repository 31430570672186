const IconSpeed = ({fill='#054FD6'}) => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0C13.6866 0 15.2935 0.348492 16.7512 0.977613L17.0791 1.125C17.4069 1.27837 17.7267 1.44613 18.0377 1.62747C18.0949 1.66085 18.1518 1.69469 18.2085 1.72899C19.0388 2.2319 19.803 2.83249 20.4853 3.51474C22.6558 5.68521 24 8.68659 24 12C24 12.4251 23.9779 12.8453 23.9346 13.2595C23.8773 13.8088 23.3855 14.2076 22.8362 14.1503C22.2869 14.0929 21.8881 13.6012 21.9454 13.0519C21.9815 12.7065 22 12.3556 22 12C22 9.23832 20.8818 6.73969 19.0711 4.92895C18.5018 4.35967 17.8644 3.85882 17.1724 3.43969C17.0781 3.38259 16.9828 3.32701 16.8865 3.27297C16.5864 3.10454 16.2768 2.95118 15.9587 2.81389L15.9542 2.81195C14.7421 2.28952 13.4057 2 12 2C10.4886 2 9.05756 2.33468 7.77508 2.93344C7.61455 3.00838 7.45635 3.08747 7.30061 3.17055L7.23409 3.20636C6.38407 3.66811 5.60805 4.24981 4.92893 4.92893C3.11819 6.73967 2 9.2383 2 12C2 14.0138 2.59424 15.8858 3.61662 17.4538C3.91827 17.9164 3.78776 18.536 3.32513 18.8376C2.86249 19.1393 2.24293 19.0088 1.94128 18.5462C0.713493 16.6631 0 14.4134 0 12C0 8.68657 1.34424 5.6852 3.51472 3.51472C4.32856 2.70088 5.25907 2.0032 6.2794 1.44893C6.38574 1.39116 6.49305 1.33495 6.60131 1.28033C6.81553 1.17224 7.03348 1.07036 7.25489 0.974949L7.26055 0.97253C8.71532 0.346537 10.3182 0 12 0Z"
        fill={ fill }
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0766 9.79929L22.982 17.0465C23.982 17.8604 23.0662 19.4466 21.8614 18.9875L11.1324 14.8987C11.022 14.8566 10.9157 14.8089 10.8137 14.7559C10.7074 14.7099 10.6027 14.6574 10.4999 14.5981C9.06506 13.7697 8.57343 11.9349 9.40186 10.5C10.2303 9.06514 12.0651 8.57351 13.4999 9.40194C13.6023 9.46103 13.6998 9.52525 13.7925 9.59407C13.8897 9.65608 13.9846 9.72445 14.0766 9.79929ZM12.4999 11.1342C12.0216 10.858 11.41 11.0219 11.1338 11.5002C10.8577 11.9785 11.0216 12.5901 11.4999 12.8662C11.9782 13.1424 12.5897 12.9785 12.8659 12.5002C13.142 12.0219 12.9782 11.4103 12.4999 11.1342Z"
        fill={ fill }
      />
    </svg>
  )
}

export default IconSpeed;
