import IconCheckMark from "../../../icons/IconCheckMark";
import IconMovie from '@/assets/img/movie-icon.svg'
import IconPC from "../../../icons/IconPC";
import React from "react";

const TariffInfoKion = ({ title, speed, channels, movies, tags, isModal }) => {
  return (
    <div className="tariff-card__info tariff-card__smotreshka width-full zi-2">
      <h3 className="mb-4">{title}</h3>

      <div className="tariff-card__advantages-wrapper mb-6">
        <div className="tariff-card__advantages">
          <div className="tariff-card__advantages__title flex flex-aic gap-2">
            <IconPC/>
            <p className="tariff-card__advantages__title body body-2">{ speed } Мбит/с</p>
          </div>
        </div>


        {
          !!movies.length
          && <div className="tariff-card__advantages mb-6">
            <div className="tariff-card__advantages__title flex flex-aic gap-2">
              <img src={IconMovie} alt=""/>
              <p className="tariff-card__advantages__content body body-2">{movies.join(', ')}</p>
            </div>
          </div>
        }
      </div>

      {
        tags?.map((tag, idx) => (
          ((!isModal && idx < 4) || isModal)
          && <div key={idx} className="tariff-card__available flex flex-aic mb-3 gap-3">
            <IconCheckMark/>
            <p className="body body-6 opacity-10">{tag}</p>
          </div>
        ))
      }
    </div>
  )
}

export default TariffInfoKion;
