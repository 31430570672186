const IconProfile = ({ fill = '#FFFFFF' }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.2692 18.2692C9.08186 17.4565 10.1841 17 11.3333 17H18C19.1493 17 20.2515 17.4565 21.0641 18.2692C21.8768 19.0819 22.3333 20.1841 22.3333 21.3333V23C22.3333 23.5523 21.8856 24 21.3333 24C20.781 24 20.3333 23.5523 20.3333 23V21.3333C20.3333 20.7145 20.0875 20.121 19.6499 19.6834C19.2123 19.2458 18.6188 19 18 19H11.3333C10.7145 19 10.121 19.2458 9.68342 19.6834C9.24583 20.121 9 20.7145 9 21.3333V23C9 23.5523 8.55228 24 8 24C7.44772 24 7 23.5523 7 23V21.3333C7 20.1841 7.45655 19.0819 8.2692 18.2692Z" fill={ fill }/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.6668 9C13.3782 9 12.3335 10.0447 12.3335 11.3333C12.3335 12.622 13.3782 13.6667 14.6668 13.6667C15.9555 13.6667 17.0002 12.622 17.0002 11.3333C17.0002 10.0447 15.9555 9 14.6668 9ZM10.3335 11.3333C10.3335 8.9401 12.2736 7 14.6668 7C17.0601 7 19.0002 8.9401 19.0002 11.3333C19.0002 13.7266 17.0601 15.6667 14.6668 15.6667C12.2736 15.6667 10.3335 13.7266 10.3335 11.3333Z" fill={ fill }/>
    </svg>
  )
}

export default IconProfile;
