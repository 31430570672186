import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ExtraReducerBuilder, setItem, setItems, setSuccess } from "../../utils/redux";
import AsyncThunkService from "../../services/AsyncThinkService";
import {$axios} from "../../utils/api";

const api = new AsyncThunkService('/promotions', 'promotions');

export const promotionsFetch = createAsyncThunk(
    'promotions/fetch',
    async (location, { rejectWithValue }) => {
      try {
        const { data } = await $axios.get('/promotions', {
          params: {
            location: location
          }
        });

        return data;
      } catch (e) {
        const { message } = e.response.data;
        return rejectWithValue(message || e.message);
      }
    }
);

// export const promotionsFetch = api.fetchAll();
export const promotionsFetchOne = api.fetchOne();

const initialState = {
  items: [],
  item: null,
  status: null,
  error: null
}

export const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const extraReducerBuild = new ExtraReducerBuilder(builder);

    extraReducerBuild.addCases(promotionsFetch, (state, { payload }) => {
      setSuccess(state);
      state.items = payload.map(news => ({ type: 'STOCK', ...news, }));
    })
    extraReducerBuild.addCases(promotionsFetchOne, setItem)
  }
})
