const IconSearch = ({ fill = '#FFFFFF' }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.60294 13.8529C8.60294 10.9534 10.9534 8.60294 13.8529 8.60294C16.7524 8.60294 19.1029 10.9534 19.1029 13.8529C19.1029 15.2639 18.5463 16.5449 17.6407 17.4883C17.6128 17.5104 17.5859 17.5343 17.5601 17.5601C17.5343 17.5859 17.5104 17.6128 17.4883 17.6407C16.5449 18.5463 15.2639 19.1029 13.8529 19.1029C10.9534 19.1029 8.60294 16.7524 8.60294 13.8529ZM18.1777 19.4879C16.98 20.4086 15.4804 20.9559 13.8529 20.9559C9.9301 20.9559 6.75 17.7758 6.75 13.8529C6.75 9.9301 9.9301 6.75 13.8529 6.75C17.7758 6.75 20.9559 9.9301 20.9559 13.8529C20.9559 15.4804 20.4086 16.98 19.4879 18.1777L22.2288 20.9185C22.5906 21.2804 22.5906 21.867 22.2288 22.2288C21.867 22.5906 21.2804 22.5906 20.9185 22.2288L18.1777 19.4879Z" fill={ fill }/>
    </svg>

  )
}

export default IconSearch;
