const Logo = ({ fill = '#2f51d2'  }) => {
  return (
    <svg width="110px" height='57px' id="Слой_1" data-name="Слой 1" xmlns="http://www.w3.org/2000/svg" viewBox="120 20 840 460">
      <path fill={fill}
            d="M979.39,175.39a26.22,26.22,0,1,1-26.16-26.16A26.06,26.06,0,0,1,979.39,175.39Zm-5.54,0a20.68,20.68,0,1,0-20.61,20.74A20.58,20.58,0,0,0,973.85,175.39Zm-8.55,4.77v8.55h-5.65V181a1.69,1.69,0,0,0-1.76-1.63H947.45v9.3h-5.78v-26.9H956c5.53,0,9.3,2.76,9.18,7.54,0,3.14-1.38,5-3.77,6C964.17,175.64,965.3,177.15,965.3,180.16Zm-17.85-5.78h8c2.39,0,4-1.38,4-3.52s-1.76-3.52-4-3.52h-8Z"/>
      <path fill={fill}
            d="M325.72,310.09H161.05c3.51,22.33,22.78,41.17,53,42.48,20.14.88,39.85-8.32,47.73-22.34h61.75C309.08,378.85,265.72,406,214.05,406c-73.58,0-113.44-54.31-113.44-115.62,0-64.82,46.87-115.62,113.87-115.62C287.18,174.76,333.61,233,325.72,310.09Zm-164.23-46H265.72c-6.57-22.77-26.27-36.35-51.67-36.35C188.21,227.75,167.62,242.21,161.49,264.1Z"/>
      <path fill={fill}
            d="M649.29,264.1c-7-18.83-25-32.41-48.62-32.41-34.6,0-56.06,26.72-56.06,58.69s21.46,58.25,56.06,58.25c23.21,0,41.61-13.14,48.62-32.41H711C701.84,368.34,659.8,406,600.23,406c-70.51,0-114.74-50.8-114.74-115.62s44.23-115.62,114.74-115.62c60,0,102,37.23,110.81,89.34Z"/>
      <path fill={fill}
            d="M718.43,290.38c0-64.82,46-115.62,117.37-115.62s117.37,50.8,117.37,115.62C953.17,355.63,906.75,406,835.8,406S718.43,355.63,718.43,290.38Zm175.62,0c0-32.41-23.21-58.69-58.25-58.69S777.55,258,777.55,290.38s23.21,58.25,58.25,58.25C870.4,348.63,894.05,322.79,894.05,290.38Z"/>
      <rect fill={fill} x="337.68" y="106" width="59.12" height="300"/>
      <rect fill={fill} x="411.58" y="106" width="59.12" height="300"/>
    </svg>
  )
}

export default Logo;
