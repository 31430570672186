const IconPC = ({ fill='#054FD6' }) => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6 0C19.8402 0 20.9603 0 21.816 0.435974C22.5686 0.819467 23.1805 1.43139 23.564 2.18404C24 3.03969 24 4.15979 24 6.4V10.6C24 12.8402 24 13.9603 23.564 14.816C23.1805 15.5686 22.5686 16.1805 21.816 16.564C20.9603 17 19.8402 17 17.6 17L16.4999 17L17.6331 18.3598C18.1759 19.0111 17.7127 20 16.8649 20H7.13497C6.28713 20 5.82397 19.0111 6.36675 18.3598L7.49993 17L6.4 17C4.15979 17 3.03968 17 2.18404 16.564C1.43139 16.1805 0.819467 15.5686 0.435973 14.816C0 13.9603 0 12.8402 0 10.6V6.4C0 4.15979 0 3.03968 0.435974 2.18404C0.819467 1.43139 1.43139 0.819466 2.18404 0.435973C3.03968 0 4.15979 0 6.4 0H17.6ZM22 6.4V10.6C22 11.7531 21.9984 12.4977 21.9521 13.0643C21.9078 13.6077 21.8324 13.8091 21.782 13.908C21.5903 14.2843 21.2843 14.5903 20.908 14.782C20.8091 14.8324 20.6077 14.9078 20.0643 14.9521C19.4977 14.9984 18.7531 15 17.6 15L6.4 15C5.24689 15 4.50235 14.9984 3.93567 14.9521C3.39234 14.9078 3.19091 14.8324 3.09202 14.782C2.71569 14.5903 2.40973 14.2843 2.21799 13.908C2.1676 13.8091 2.09224 13.6077 2.04785 13.0643C2.00155 12.4977 2 11.7531 2 10.6L2 6.4C2 5.24689 2.00155 4.50235 2.04785 3.93567C2.09224 3.39234 2.1676 3.19091 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.19091 2.1676 3.39234 2.09225 3.93567 2.04785C4.50235 2.00155 5.24689 2 6.4 2L17.6 2C18.7531 2 19.4977 2.00156 20.0643 2.04785C20.6077 2.09225 20.8091 2.1676 20.908 2.21799C21.2843 2.40973 21.5903 2.71569 21.782 3.09202C21.8324 3.19091 21.9078 3.39235 21.9521 3.93567C21.9984 4.50235 22 5.24689 22 6.4Z"
        fill={ fill }
      />
    </svg>
  )
}

export default IconPC;
